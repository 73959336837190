<script setup lang="ts">
import { onBeforeMount, ref } from 'vue';

import {
  WsSelect,
  WsMenu,
  WsSelectOption,
  WsMenuItem,
  WsButton,
} from '@mfl/common-components';
import {
  signatureTemplateGateway,
  Template,
} from '@msl/signature-template-gateway-sdk';
import { auth4Sdks } from '@mfl/platform-shell';

import strings from './editor.strings';
import { createTemplate, updateTemplate, deleteTemplate } from './editor-utils';

const emits = defineEmits(['change']);

const templates = ref<Template[]>([]);
const selectedTemplate = ref<Template>();

onBeforeMount(async () => {
  await signatureTemplateGateway.init(auth4Sdks());
  templates.value = await signatureTemplateGateway.get();

  if (templates.value.length > 0) {
    selectedTemplate.value = templates.value[0];
  }
});

async function create() {
  const res = await createTemplate();
  if (res) {
    templates.value.push(res);
    selectedTemplate.value = res;
  }
}

async function rename() {
  if (selectedTemplate?.value) {
    const res = await updateTemplate(
      selectedTemplate?.value.id ?? '',
      templates.value
    );
    if (res) {
      selectedTemplate.value.name = res;
    }
  }
}

async function deleteCurrent() {
  if (selectedTemplate?.value) {
    const templateId = selectedTemplate.value.id;
    const result = await deleteTemplate(templateId, templates.value);

    if (result) {
      templates.value = templates.value.filter((tt) => tt.id !== templateId);
      if (templates.value.length > 0) {
        selectedTemplate.value = templates.value[0];
      } else {
        selectedTemplate.value = undefined;
        emits('change', undefined);
      }
    }
  }
}
</script>

<template>
  <div v-if="selectedTemplate" class="flex gap-5">
    <WsButton
      aid="EDITOR_HEADER_BACK"
      label="Back"
      icon="fa-regular fa-chevron-left"
      variant="text"
      color="gray-500"
      type="button"
    />

    <div class="h-4 border-l border-solid border-gray-500"></div>

    <WsSelect
      v-model="selectedTemplate"
      class="font-semibold"
      aid="EDITOR_HEADER_WIDGET_SELECT"
      variant="text"
      size="lg"
      :option-key="(val) => val.id ?? ''"
      :option-label="(val) => val.name"
      :update:model-value="emits('change', selectedTemplate)"
    >
      <WsSelectOption v-for="t in templates" :key="t.id" :value="t">
        {{ t.name }}
      </WsSelectOption>
    </WsSelect>
    <button
      class="flex cursor-default items-center gap-2 focus:outline-none focus-visible:ring-2 focus-visible:ring-white/75 focus-visible:ring-offset-2 focus-visible:ring-offset-primary font-semibold"
    >
      <span>{{ strings.actions }}</span>
      <span class="fa-regular fa-chevron-down text-xs" aria-hidden="true" />
      <WsMenu aid="EDITOR_HEADER_WIDGWT_MENU">
        <WsMenuItem
          aid="EDITOR_HEADER_WIDGWT_MENU_CREATE"
          icon="fa-light fa-circle-plus"
          @click="create"
          >{{ strings.createTemplate }}</WsMenuItem
        >
        <WsMenuItem
          aid="EDITOR_HEADER_WIDGWT_MENU_RENAME"
          icon="fa-light fa-pen"
          @click="rename"
          >{{ strings.renameTemplate }}</WsMenuItem
        >
        <WsMenuItem
          aid="EDITOR_HEADER_WIDGWT_MENU_DELETE"
          icon="fa-light fa-trash-can"
          @click="deleteCurrent"
          >{{ strings.deleteTemplate }}</WsMenuItem
        >
      </WsMenu>
    </button>
  </div>
</template>
